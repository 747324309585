<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-no-padding">
            <ion-item v-for="(index) in template.tabsCount" :key="index" lines="full" mode="md">
                <ion-label position="stacked">{{ language.tab }} {{ getTranslatedNumber(index) }} {{ language.title }}</ion-label>
                <ion-input autocomplete="off" :placeholder="language.title" v-model="template.tabs[index-1].title"/>
                <ion-item v-if="!selectedIcons[index-1] || selectedIcons[index-1].data === undefined" lines="full" mode="md">
                    <button class="custom-button button-outline" @click="openImageSelectModal(index-1)">{{ language.tabIconButton }}</button>
                </ion-item>                 
                <ion-item v-else lines="full" mode="md">
                    <ion-label >{{ language.tab }} {{ getTranslatedNumber(index) }} {{ language.icon }}</ion-label>
                    <ion-item lines="full" mode="md">
                        <ion-icon slot="start" :src="selectedIcons[index-1].data" class="icon-preview"></ion-icon>
                        <!--<ion-label>
                            <h2>{{ selectedIcons[index-1].filename }}</h2>
                        </ion-label>-->
                        <div class="icon-container" @click="removeImg(index-1)"><ion-icon :icon="closeOutline" class="edit-forward"></ion-icon></div>
                    </ion-item>
                </ion-item>
            </ion-item>
        </ion-content>
        <VisualTabs :tabs="template.tabs"/>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">

import {IonContent, IonInput, IonItem, IonLabel, IonPage, IonIcon} from '@ionic/vue';
import FixedFooter from "@/components/FixedFooter.vue";
import VisualTabs from "@/components/VisualTabs.vue";
import {computed, defineComponent, ref} from "vue";
import {JourneyTemplate} from "@/models/JourneyTemplateModel";
import {execute, executeWithoutSpinner, saveProgress} from "@/mixins/LoadingMixin";
import {useRoute} from "vue-router";
import router from "@/router";
import { closeOutline } from 'ionicons/icons';
import {openSelectImageModal} from "@/services/SelectModalService";
import {IMAGE_GROUP_TYPES, ImageGroupId} from "@/models/ImageGroupTypes";
import {Image} from "@/models/ImageModel";
import {presentValidationAlert} from "@/services/Notify";
import {JourneyTab} from "@/models/JourneyTabModel";
import {getTemplate} from "@/services/TemplateService";
import {createTabs, updateTabTitles} from "@/services/TabService";
import {getImage} from "@/services/ImagesService";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {getTranslatedNumber} from "@/utils/NumberTranslate";
import {useStore} from "vuex";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";

export default defineComponent({
    name: 'TabTitles',
    components: {
        IonContent,
        IonPage,
        IonItem,
        IonLabel,
        IonInput,
        IonIcon,
        VisualTabs,
        FixedFooter
    },
    async ionViewWillLeave() {
        await saveProgress(this.save);
        clearInterval(this.saveInterval);
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.templateTabs);
        await execute(async () => {
            this.template = await getTemplate(this.currentId);
            if (this.template.tabs.length === 0 || !this.template.tabs) {
                for (let i = 1; i <= this.template.tabsCount; i++) {
                    let tabNumber = i;
                    if (i==this.template.tabsCount ){
                        tabNumber = 4;
                    } 
                    console.log(`Tab count: ${this.template.tabsCount} , i: ${i} , tabNumber: ${tabNumber}`);
                    this.template.tabs.push({
                        id: uuid4(),
                        title: '',
                        tabIconId: '00000000-0000-0000-0000-000000000000',
                        tabNum: tabNumber,
                        templateId: this.currentId
                    } as JourneyTab);

                }
                await createTabs(this.template.tabs);
            }else{
                console.log(`Existing Tabs:`);
                console.log((this.template.tabs));
            }
        }, this.language.errors.gettingTemplate);

        //Reset the Icons Array
        if (this.selectedIcons.length > 0){
            this.selectedIcons.length = 0;
        }

        //Load tab icons
        for (let i = 0; i <= this.template.tabsCount - 1; i++){
            let tabIcon = '';

            if (this.template.tabs[i].tabIconId === '00000000-0000-0000-0000-000000000000' || this.template.tabs[i].tabIconId === undefined) {
                tabIcon = 'No Matching Image';
            }
            else {                
                tabIcon = this.template.tabs[i].tabIconId;
            }

            if (tabIcon != 'No Matching Image'){
                this.selectedIcon = await getImage(tabIcon);
                this.template.tabs[i].tabIconId = tabIcon;
            }
            else{              
                this.selectedIcon = {} as Image;
            }
            this.selectedIcons.push(this.selectedIcon);
        }
        //this.saveInterval = setInterval(this.save, 30000);
    },
    setup: function () {
        const store = useStore();
        const selectedIcon = ref({} as Image);
        const selectedIcons = ref<Image[]>([]);
        const language = computed(() => store.getters['app/language']);
        const route = useRoute();
        const template = ref({} as JourneyTemplate);
        const currentId = computed(() => route.params.id as string);
        const saveInterval = ref();

        const save = async () => {
            await executeWithoutSpinner(async() => {
                await updateTabTitles(template.value.tabs);
            }, language.value.errors.savingProgress);
        }

        const openImageSelectModal = async (index: number) => {
            const imageSelect = await openSelectImageModal(IMAGE_GROUP_TYPES.get(ImageGroupId.JourneySetIcon) || '', ImageGroupId.JourneySetIcon);
            const { data } = await imageSelect.onWillDismiss();

            data.title = template.value.tabs[index].title;
            template.value.tabs[index].tabIconId = data.id;
            template.value.tabs[index].icon = data.data;
            selectedIcons.value[index] = data;
        }

        const removeImg = (index: number) => {
            template.value.tabs[index].tabIconId = '00000000-0000-0000-0000-000000000000';
            template.value.tabs[index].icon = 'remove';
            selectedIcons.value[index] = {} as Image;           
        }
        
        return {
            store,
            language,
            saveInterval,
            closeOutline,
            save,
            openImageSelectModal,
            selectedIcon,
            selectedIcons,
            removeImg,
            template,
            currentId,
            getTranslatedNumber,
            footerButtons: computed(() =>
                new FooterButtonsBuilder()
                    .addClearBackButton()
                    .addPrimaryNextButton(
                        async () => {
                            let errorMessage = '';
                            if (template.value.tabs.some((tab: JourneyTab) => !tab.title || tab.title.trim() === '')) {
                                errorMessage = language.value.validation.fillAllTitles;
                            } 
                            else if (template.value.tabs.some((tab: JourneyTab) => !tab.tabIconId || tab.tabIconId.trim() === '00000000-0000-0000-0000-000000000000')) {
                                errorMessage = language.value.validation.selectIcon;
                            } 
                            else { 
                                await save();
                                router.push({ name: 'tab', params: {id: currentId.value, tabId: template.value.tabs[0].id }});
                            }
                            if (errorMessage){
                                presentValidationAlert(errorMessage);
                            }

                        /* const error = template.value.tabs.some((tab: JourneyTab) => !tab.title || tab.title.trim() === '');
                        if (!error) {
                            await save();
                            router.push({ name: 'tab', params: {id: currentId.value, tabId: template.value.tabs[0].id }});
                        } else {
                            presentValidationAlert(language.value.validation.fillAllTitles);
                        } */
                    })
                    .create()
            )
        }
    }
});
</script>

<style scoped>
p {
    font-size: 20px;
    line-height: 26px;
    color: var(--color-step-300);
}
.custom-button {
    margin-bottom: 20px;
}

.image-wrapper {
    display: flex;
    justify-content: center;
}

.custom-content-layout {
    max-width: 80%;
}
</style>